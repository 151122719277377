import React, { useState, useEffect } from 'react';
import {
    Box, Button, Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GetAppIcon from '@mui/icons-material/GetApp';
import SearchIcon from '@mui/icons-material/Search';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DrawerAppBar from './NavigationBar';
import BuildingCard from './BuildingCard';

interface Building {
    id: number;
    name: string;
    name_en: string;
    description: string;
    description_en: string;
    tags: string[];
    tags_en: string[];
    categories: string[];
    categories_en: string[];
    image_id: string;
    latitude: string;
    longitude: string;
    num_called: number;
    created_at: string;
    updated_at: string;
}

const BuildingConsole = () => {
    const [buildings, setBuildings] = useState<Building[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        name_en: '',
        description: '',
        description_en: '',
        tags: '',
        tags_en: '',
        categories: '',
        categories_en: '',
        file: null as File | null
    });

    const fetchBuildings = async () => {
        const response = await fetch('https://hirodaimaps.ikeda042api.net/api/buildings/');
        const data = await response.json();
        setBuildings(data);
    };

    useEffect(() => {
        fetchBuildings();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, files } = event.target;
        setFormValues({
            ...formValues,
            [name]: files ? files[0] : value
        });
    };

    const handleFileUpload = async (file: File): Promise<string> => {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch('https://hirodaimaps.ikeda042api.net/api/files/upload', {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            const data = await response.json();
            return data.file_id;
        } else {
            throw new Error('File upload failed');
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let file_id = "";
        if (formValues.file) {
            try {
                file_id = await handleFileUpload(formValues.file);
            } catch (error) {
                console.error('Error during file upload:', error);
            }
        }

        const buildingData = {
            name: formValues.name,
            name_en: formValues.name_en,
            description: formValues.description,
            description_en: formValues.description_en,
            tags: formValues.tags,
            tags_en: formValues.tags_en,
            categories: formValues.categories,
            categories_en: formValues.categories_en,
            image_id: file_id
        };

        const response = await fetch('https://hirodaimaps.ikeda042api.net/api/buildings/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(buildingData)
        });

        if (response.ok) {
            fetchBuildings();
            handleClose();
        } else {
            console.error('Failed to submit form:', response.statusText);
        }
    };

    const handleLoadCsv = () => {
        // Implement CSV load functionality
    };

    const handleDownload = () => {
        // Implement CSV download functionality
    };

    return (
        <Box sx={{ bgcolor: "#f7f6f5", color: 'black', minHeight: '100vh' }}>
            <DrawerAppBar />
            <Grid container spacing={4} margin={5} mt={10}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                        style={{
                            backgroundColor: '#085c3c',
                            color: '#fff',
                            height: '56px',
                            marginRight: '8px'
                        }}
                        startIcon={<AddIcon />}
                        size="medium"
                    >
                        新しい建物を追加
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLoadCsv}
                        style={{
                            backgroundColor: '#085c3c',
                            color: '#fff',
                            height: '56px',
                            marginRight: '8px'
                        }}
                        startIcon={<UploadFileIcon />}
                        size="medium"
                    >
                        csv一括ロード
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownload}
                        style={{
                            backgroundColor: '#085c3c',
                            color: '#fff',
                            height: '56px',
                            marginRight: '8px'
                        }}
                        startIcon={<GetAppIcon />}
                        size="medium"
                    >
                        csvエクスポート
                    </Button>
                    <TextField
                        label="検索"
                        value={searchTerm}
                        onChange={event => setSearchTerm(event.target.value)}
                        variant="outlined"
                        size="medium"
                        style={{
                            height: '56px',
                            width: '50%',
                        }}
                        InputProps={{
                            style: { height: '56px' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                {buildings.filter(building => building.name.includes(searchTerm) || building.description.includes(searchTerm)).map((building) => (
                    <Grid item xs={12} sm={6} md={3} key={building.id}>
                        <BuildingCard
                            buildingId={building.id.toString()}
                            imageURL={`https://hirodaimaps.ikeda042api.net/api/files/${building.image_id}`}
                            title={building.name}
                            title_en={building.name_en}
                            description={building.description}
                            description_en={building.description_en}
                            tags={Array.isArray(building.tags) ? building.tags.join(', ') : 'No tags available'}
                        />
                    </Grid>
                ))}
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>新しい建物を追加</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        建物の詳細を入力してください。（後で編集可能です）
                    </DialogContentText>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="name"
                            label="建物名"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        <TextField
                            margin="dense"
                            name="name_en"
                            label="Building Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        <TextField
                            margin="dense"
                            name="description"
                            label="建物の説明"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        <TextField
                            margin="dense"
                            name="description_en"
                            label="Building Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        <TextField
                            margin="dense"
                            name="tags"
                            label="タグ (カンマ区切り)"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        <TextField
                            margin="dense"
                            name="tags_en"
                            label="Tags (English, comma separated)"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        <TextField
                            margin="dense"
                            name="categories"
                            label="カテゴリ (カンマ区切り)"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        <TextField
                            margin="dense"
                            name="categories_en"
                            label="Categories (comma separated)"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        <Button
                            variant="contained"
                            component="label"
                            size="medium"
                            sx={{ backgroundColor: 'gray', color: 'white' }}
                            startIcon={<FileUploadIcon />}
                        >
                            画像ファイルを選択する
                            <input
                                type="file"
                                name="file"
                                hidden
                                onChange={handleChange}
                            />
                        </Button>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                キャンセル
                            </Button>
                            <Button type="submit" color="primary">
                                追加
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default BuildingConsole;
