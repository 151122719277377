import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useState } from 'react';
import { useLanguage } from './NavigationBar';

interface BuildingCardProps {
    buildingId: string;
    imageURL: string;
    title: string;
    title_en: string;
    description: string;
    description_en: string;
    tags: string;
}

export default function BuildingCard({ buildingId, imageURL, title, title_en, description, description_en, tags }: BuildingCardProps) {
    const [openDialog, setOpenDialog] = useState(false);
    const { language, setLanguage } = useLanguage();

    const isJapanese = language === 'ja';

    const handleDelete = async () => {
        await axios.delete(`https://hirodaimaps.ikeda042api.net/api/buildings/${buildingId}`);
        setOpenDialog(false);
        window.location.reload();
    };
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                sx={{ height: 140 }}
                image={imageURL}
                title={isJapanese ? title : title_en}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {isJapanese ? title : title_en}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {isJapanese ? description : description_en}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" variant='outlined' color='info' href={`/buildings/${buildingId}`}>建物データの編集</Button>
                <Button size="small" variant='outlined' color='error' onClick={() => setOpenDialog(true)}>削除</Button>
            </CardActions>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>建物を削除</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        本当にこの建物を削除しますか？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>キャンセル</Button>
                    <Button onClick={handleDelete} color="error">削除</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}