
import { Typography } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './components/MainPage';
import BuildingPage from './components/BuildingPage';
import IPhoneMockup from './components/Iphone';
import { LanguageProvider } from './components/NavigationBar';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <LanguageProvider>
            <MainPage />
          </LanguageProvider>
        } />
        <Route path="/buildings/:buildingId" element={
          <LanguageProvider>
            <BuildingPage />
          </LanguageProvider>
        } />
        <Route path="/mockup" element={<IPhoneMockup />} />
      </Routes>
    </Router >
  );
}

export default App;