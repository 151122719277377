import * as React from 'react';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, Card, CardContent, CardMedia, Typography, Stack, RadioGroup, FormControlLabel, Radio, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DrawerAppBar from './NavigationBar';
import Map from './Map';
import PublishIcon from '@mui/icons-material/Publish';
import RefreshIcon from '@mui/icons-material/Refresh';
import ImageIcon from '@mui/icons-material/Image';
import Grid2 from '@mui/material/Unstable_Grid2';

interface Building {
    id: number;
    name: string;
    name_en: string;
    description: string;
    description_en: string;
    is_active: boolean;
    tags: string;
    tags_en: string;
    categories: string;
    categories_en: string;
    image_id: string;
    latitude: string;
    longitude: string;
    num_called: number;
    created_at: string;
    updated_at: string;
}

export default function BuildingPage() {
    const { buildingId } = useParams<{ buildingId: string }>();
    const [mapId, setMapId] = useState('');
    const [building, setBuilding] = useState<Building | null>(null);
    const [title, setTitle] = useState('');
    const [title_en, setTitleEn] = useState('');
    const [description, setDescription] = useState('');
    const [description_en, setDescriptionEn] = useState('');
    const [tags, setTags] = useState('');
    const [tags_en, setTagsEn] = useState('');
    const [latitude, setLatitude] = useState(34.403814);
    const [longitude, setLongitude] = useState(132.715201);
    const [file, setFile] = useState<File | null>(null);
    const [open, setOpen] = useState(false);
    const [moveMode, setMoveMode] = useState<'large' | 'medium' | 'small'>('large');
    const [isActive, setIsActive] = useState(true);

    const handleLatitudeIncrease = () => {
        const increment = moveMode === 'large' ? 0.001 : moveMode === 'medium' ? 0.0001 : 0.00001;
        setLatitude(prev => prev + increment);
    };

    const handleLatitudeDecrease = () => {
        const decrement = moveMode === 'large' ? 0.001 : moveMode === 'medium' ? 0.0001 : 0.00001;
        setLatitude(prev => prev - decrement);
    };

    const handleLongitudeIncrease = () => {
        const increment = moveMode === 'large' ? 0.001 : moveMode === 'medium' ? 0.0001 : 0.00001;
        setLongitude(prev => prev + increment);
    };

    const handleLongitudeDecrease = () => {
        const decrement = moveMode === 'large' ? 0.001 : moveMode === 'medium' ? 0.0001 : 0.00001;
        setLongitude(prev => prev - decrement);
    };

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`https://hirodaimaps.ikeda042api.net/api/buildings/${buildingId}`)
            .then(response => {
                const data = response.data;
                setBuilding(data);
                setTitle(data.name);
                setTitleEn(data.name_en);
                setDescription(data.description);
                setDescriptionEn(data.description_en);
                setTags(data.tags);
                setTagsEn(data.tags_en);
                setLatitude(Number(data.latitude));
                setLongitude(Number(data.longitude));
                setIsActive(data.is_active);
            })
            .catch(error => console.error('Error fetching building data:', error));
    }, [buildingId]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFile(event.target.files ? event.target.files[0] : null);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        let image_id = building?.image_id;

        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            const response = await fetch('https://hirodaimaps.ikeda042api.net/api/files/upload', {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                image_id = data.file_id;
            } else {
                throw new Error('File upload failed');
            }
        }

        const updatedBuilding = {
            name: title,
            name_en: title_en,
            description,
            description_en,
            tags,
            tags_en,
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            image_id: image_id ?? '',
            categories: building?.categories ?? '',
            categories_en: building?.categories_en ?? '',
            is_active: isActive,
        };

        await axios.patch(`https://hirodaimaps.ikeda042api.net/api/buildings/${buildingId}`, updatedBuilding, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer YOUR_TOKEN_HERE' // 適切なトークンを設定してください
            }
        });

        setBuilding({ ...building, ...updatedBuilding } as Building);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ bgcolor: "#f7f6f5", color: 'black', minHeight: '100vh' }}>
            <DrawerAppBar />
            <Grid2 container spacing={4} margin={5} mt={10} justifyContent="center">
                <Grid2 xs={12} md={6}>
                    {building && (
                        <>
                            <Card sx={{ width: '100%', maxWidth: 450, margin: 'auto' }}>
                                <CardMedia
                                    sx={{ height: 190 }}
                                    image={`https://hirodaimaps.ikeda042api.net/api/files/${building.image_id}`}
                                    title={building.name}
                                />
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {description}
                                    </Typography>
                                </CardContent>
                            </Card>
                            <br />
                            <Box sx={{ width: '100%', maxWidth: 450, margin: 'auto', aspectRatio: '1' }}>
                                <Map lat={`${latitude}`} lon={`${longitude}`} title={title} />
                            </Box>
                            <Grid2 container spacing={3} justifyContent="center" mt={4}>
                                <Grid2 xs={12} container justifyContent="center">
                                    <RadioGroup row value={moveMode} onChange={event => setMoveMode(event.target.value as 'large' | 'medium' | 'small')}>
                                        <FormControlLabel value="large" control={<Radio />} label="移動モード(大)" />
                                        <FormControlLabel value="medium" control={<Radio />} label="移動モード(中)" />
                                        <FormControlLabel value="small" control={<Radio />} label="移動モード(小)" />
                                    </RadioGroup>
                                </Grid2>
                                <Grid2 xs={4}></Grid2>
                                <Grid2 xs={4} container justifyContent="center">
                                    <Button onClick={handleLatitudeIncrease} variant='contained' sx={{ backgroundColor: 'black', color: 'white' }}>↑</Button>
                                </Grid2>
                                <Grid2 xs={4}></Grid2>
                                <Grid2 xs={4}></Grid2>
                                <Grid2 xs={4}></Grid2>
                                <Grid2 xs={4}></Grid2>
                                <Grid2 xs={4} container justifyContent="flex-end">
                                    <Button onClick={handleLongitudeDecrease} variant='contained' sx={{ backgroundColor: 'black', color: 'white' }}>←</Button>
                                </Grid2>
                                <Grid2 xs={4} container justifyContent="center">
                                    <Button onClick={handleLatitudeDecrease} variant='contained' sx={{ backgroundColor: 'black', color: 'white' }}>↓</Button>
                                </Grid2>
                                <Grid2 xs={4} container justifyContent="flex-start">
                                    <Button onClick={handleLongitudeIncrease} variant='contained' sx={{ backgroundColor: 'black', color: 'white' }}>→</Button>
                                </Grid2>
                            </Grid2>
                        </>
                    )}
                </Grid2>
                <Grid2 xs={12} md={6}>
                    <Box sx={{ width: '100%', maxWidth: 450, margin: 'auto' }}>
                        <Typography variant="h5" component="div" mb={3}>
                            建物データの編集
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={2}>
                            編集のヒント:
                        </Typography>
                        <Typography variant="body2" color="text.secondary" mb={5}>
                            ・左側に実際の建物カード、地図のプレビューが表示される。<br />
                            ・緯度,経度は赤いピンの場所に対応している。<br />
                            ・地図中の吹き出しに表示される文字は、入力した建物名になる。<br />
                            ・画像選択ボタンから、任意の建物の画像をアップロードできる。<br />
                            ・この時、画像のIDは自動で生成され、建物データに紐づけられる。<br />
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={2}>
                            ピンの移動について
                        </Typography>
                        <Typography variant="body2" color="text.secondary" mb={5}>
                            緯度を増やすと地図上では赤いピンが上方向に移動する。<br />
                            経度を増やすと地図上では赤いピンが右方向に移動する。
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="地図のID"
                            value={mapId}
                            fullWidth
                            margin="normal"
                            onChange={event => setMapId(event.target.value)}
                        />
                        <TextField
                            label="建物名"
                            value={title}
                            fullWidth
                            margin="normal"
                            onChange={event => setTitle(event.target.value)}
                        />
                        <TextField
                            label="建物名(英語)"
                            value={title_en}
                            fullWidth
                            margin="normal"
                            onChange={event => setTitleEn(event.target.value)}
                        />
                        <TextField
                            label="建物の説明"
                            value={description}
                            fullWidth
                            margin="normal"
                            onChange={event => setDescription(event.target.value)}
                        />
                        <TextField
                            label="建物の説明(英語)"
                            value={description_en}
                            fullWidth
                            margin="normal"
                            onChange={event => setDescriptionEn(event.target.value)}
                        />
                        <TextField
                            label="タグ"
                            value={tags}
                            fullWidth
                            margin="normal"
                            onChange={event => setTags(event.target.value)}
                        />
                        <TextField
                            label="タグ(英語)"
                            value={tags_en}
                            fullWidth
                            margin="normal"
                            onChange={event => setTagsEn(event.target.value)}
                        />
                        <TextField
                            label="緯度"
                            value={latitude}
                            type="number"
                            fullWidth
                            margin="normal"
                            onChange={event => setLatitude(Number(event.target.value))}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="経度"
                            value={longitude}
                            type="number"
                            fullWidth
                            margin="normal"
                            onChange={event => setLongitude(Number(event.target.value))}
                            InputLabelProps={{ shrink: true }}
                        />
                        <FormControlLabel
                            control={<Switch checked={isActive} onChange={(event) => setIsActive(event.target.checked)} />}
                            label="アクティブ"
                            sx={{ display: 'block', mt: 2 }}
                        />
                        <Stack spacing={2} mt={2}>
                            <Grid2 container spacing={2}>
                                <Grid2 xs={4} md={4}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullWidth
                                        size="medium"
                                        sx={{ backgroundColor: 'gray', color: 'white' }}
                                        startIcon={<ImageIcon />}
                                    >
                                        画像を選択
                                        <input
                                            type="file"
                                            hidden
                                            onChange={handleFileChange}
                                        />
                                    </Button>
                                </Grid2>
                                <Grid2 xs={4} md={4}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        size="medium"
                                        sx={{ backgroundColor: 'gray', color: 'white' }}
                                        onClick={() => {
                                            setLatitude(Number(building?.latitude ?? 34.403814));
                                            setLongitude(Number(building?.longitude ?? 132.715201));
                                        }}
                                        startIcon={<RefreshIcon />}
                                    >
                                        座標リセット
                                    </Button>
                                </Grid2>
                                <Grid2 xs={4} md={4}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        size="medium"
                                        variant='contained'
                                        startIcon={<PublishIcon />}
                                        style={{ backgroundColor: '#085c3c', color: '#fff' }}
                                    >
                                        データを提出
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </Stack>
                    </form>
                </Grid2>
            </Grid2>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>
                        建物データの更新に成功しました。
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant='outlined'>
                        閉じる
                    </Button>
                    <Button
                        onClick={() => navigate('/')}
                        color="primary"
                        variant='outlined'
                    >
                        トップに戻る
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
